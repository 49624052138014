import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { global } from '../../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  token = '';
  public global = global;
  private url = global.url;

  constructor(private http: HttpClient ) { }

  getCountries(token) {
    const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/generals/country/', { headers });
  }

  getCities(token, country) {
    const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/generals/city/' + country + '/', { headers });
  }

  getOpenCountries() {
    // const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/generals/opencountry/', {  });
  }

  getOpenCities(country) {
    // const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/generals/opencity/' + country + '/', {  });
  }

  getUnities(token): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/generals/unit/', { headers });
  }

  changeUnity(token, unity) {
    const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.put(this.url + '/api/generals/changeunity/', unity, { headers });
  }

  getPaymentMethods(token): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.get(this.url + '/api/accounting/paymentmethods/', { headers });
  }

  createOpenEventParticipant(token, data) { 
    const headers = new HttpHeaders().set('X-CSRFToken', token);
    // const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.post(this.url + '/api/events/openeventparticipant/', data, {headers});
  }

  registerParticipantTicket(token, data) {
    const headers = new HttpHeaders().set('X-CSRFToken', token);
    // const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.post(this.url + '/api/events/registerparticipantticket/', data,  {headers});
  }

  ticketForgot(token, data) {
    const headers = new HttpHeaders().set('X-CSRFToken', token);
    // const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.post(this.url + '/api/events/ticketforgot/', data, {headers});
  }

  createEventParticipant(token, data) {
    const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.post(this.url + '/api/events/eventparticipant/', data, { headers });
  }

  registerInParticipantTicket(token, data) {
    const headers = new HttpHeaders().set('Authorization', 'JWT ' + token);
    return this.http.post(this.url + '/api/events/registerinparticipantticket/', data, { headers });
  }

  getCsrfToken() {
    return this.http.get(this.url + '/api/csrf-token/');
  }


}
