import { Component, OnInit } from '@angular/core';
import { MenuService } from '../core/menu/menu.service';
import { TranslatorService } from '../core/translator/translator.service';
import { UserService } from '../services/user.service';
import { menuDoctor, menu, menuReception, menuInformation, menuAcademicAdmin, menuProfessor, menuAcademicManager, menuMedicalRecordsAdmin, menuSchoolServices, menuAcademicReception, menuReceptionWithAcademic, menuClinicDirection, menuAcademicDirection, menuSchoolControl, menuDeposit, menuCEYE, menuProfessorPDoctor, menuPDoctor, menuLab, menuCongreso } from '../routes/menu';
import { DoctorService } from '../services/clinic/doctor.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    constructor(public menuService: MenuService, tr: TranslatorService, public userService: UserService) {
        let ud = userService.getUserData();
        // console.log(ud);

        let type = ud?.profile?.user_type?.id_user_type || '';
        if (type === 'doctor') {
            menuService.addMenu(menuDoctor);
        } else if(type === 'reception') {
            menuService.addMenu(menuReception);
        } else if(type === 'academic_reception') {
            menuService.addMenu(menuAcademicReception);
        } else if(type === 'information') {
            menuService.addMenu(menuInformation);
        } else if(type === 'academic_admin') {
            menuService.addMenu(menuAcademicAdmin);
        } else if(type === 'professor') {
            menuService.addMenu(menuProfessor);
        } else if(type === 'academic_manager') {
            menuService.addMenu(menuAcademicManager);
        } else if(type === 'medicalrecords') {
            menuService.addMenu(menuMedicalRecordsAdmin);
        } else if(type === 'schoolservices') {
            menuService.addMenu(menuSchoolServices);
        } else if(type === 'reception_w_academic') {
            menuService.addMenu(menuReceptionWithAcademic);
        } else if(type === 'clinic_direction') {
            menuService.addMenu(menuClinicDirection);
        } else if(type === 'academic_direction') {
            menuService.addMenu(menuAcademicDirection);
        } else if(type === 'school_control') {
            menuService.addMenu(menuSchoolControl);
        } else if(type === 'deposit') {
            menuService.addMenu(menuDeposit);
        } else if(type === 'ceye') {
            menuService.addMenu(menuCEYE);
        } else if(type === 'professor_pdoctor') {
            menuService.addMenu(menuProfessorPDoctor);
        } else if(type === 'pdoctor') {
            menuService.addMenu(menuPDoctor);
        } else if(type === 'laboratory') {
            menuService.addMenu(menuLab);
        } else if(type === 'congreso') {
            menuService.addMenu(menuCongreso);
        } else {
            menuService.addMenu(menu);
        }
    }
    ngOnInit() {
    }

}
