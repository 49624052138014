import { ModuleWithProviders } from '@angular/core';
import { Routes, CanActivate, RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './events/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { IdentityGuard, RedirectionGuard, IsProfileGuard } from '../services/identity.guard';
import { Dashboardv1Component } from './dashboard/dashboardv1/dashboardv1.component';
import { MedicalrecordsModule } from './medicalrecords/medicalrecords.module';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'getdashboard', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [IdentityGuard] },
            { path: 'getdashboard', component: Dashboardv1Component, canActivate: [RedirectionGuard] },
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'clinic', loadChildren: () => import('./clinic/clinic.module').then(m => m.ClinicModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'invoicing', loadChildren: () => import('./invoicing/invoicing.module').then(m => m.InvoicingModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'academic', loadChildren: () => import('./academic/academic.module').then(m => m.AcademicModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'accounting', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'travelexpenses', loadChildren: () => import('./travelexpenses/travelexpenses.module').then(m => m.TravelexpensesModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'library', loadChildren: () => import('./library/library.module').then(m => m.LibraryModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'information', loadChildren: () => import('./information/information.module').then(m => m.InformationModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'medicalrecords', loadChildren: () => import('./medicalrecords/medicalrecords.module').then(m => m.MedicalrecordsModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'schoolcontrol', loadChildren: () => import('./schoolcontrol/schoolcontrol.module').then(m => m.SchoolcontrolModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'clinicdirection', loadChildren: () => import('./clinic-direction/clinic-direction.module').then(m => m.ClinicDirectionModule), canActivate: [IdentityGuard, IsProfileGuard] },
            { path: 'utilities', loadChildren: () => import('./utilities/utilities.module').then(m => m.UtilitiesModule), canActivate: [IdentityGuard, IsProfileGuard] },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },

    // { path: 'register', component: RegisterComponent },
    // { path: 'recover', component: RecoverComponent },
    // { path: 'lock', component: LockComponent },
    // { path: 'maintenance', component: MaintenanceComponent },
    // { path: '404', component: Error404Component },
    // { path: '500', component: Error500Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true });
